.skills__cnt {
    max-width: 894px;
    margin: 0 auto;
    padding: 5rem 0;
}

.hompage__skils-cntt {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem 2rem;
  }
  
  .hompage__skils-itemm {
    width: 150px;
  }

  @media screen and (max-width: 500px) {
    .hompage__skils-cntt {
        gap: 1rem 1rem;
    }

    .hompage__skils-itemm {
        width: 90px;
    }
  }